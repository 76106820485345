.leaflet-bar a {
  background-color: var(--bg-alt);
}

.leaflet-popup-content {
  /* padding: var(--margin-01); */
  padding: 0 var(--margin-01);
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup,
.leaflet-popup-content-wrapper {
  border-radius: var(--margin-01);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: var(--bg-alt);
}
