:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-light: #f7f7f7;
  --color-gray-dark: #1d1f21;

  --bg: var(--color-white);
  --bg-alt: var(--color-gray-light);
  --fg: var(--color-black);
  --fg-alt: var(--color-gray-dark);

  --hl-01: #f33bb6;
  --hl-02: #aee73c;

  --warning: rgb(223, 191, 0);
  --success: #aee73c;
  --danger: #f33bb6;

  --border: var(--bg-alt);

  --font-sans: system-ui, -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
    'Ubuntu', 'Roboto', 'Noto Sans', 'Droid Sans', sans-serif;
  --font-mono: ui-monospace, 'Cascadia Mono', 'Segoe UI Mono', 'Ubuntu Mono',
    'Roboto Mono', 'Menlo', 'Monaco', 'Consolas', monospace;
  --font-serif: 'Georgia', 'Cambria', 'Times New Roman', 'Times', serif;

  --max-width: 800px;

  --margin-q: calc(var(--margin-01) / 4);
  --margin-h: calc(var(--margin-01) / 2);
  --margin-01: 8px;
  --margin-02: calc(var(--margin-01) * 2);
  --margin-03: calc(var(--margin-01) * 3);
  --margin-04: calc(var(--margin-01) * 4);
  --margin-05: calc(var(--margin-01) * 5);
  --margin-06: calc(var(--margin-01) * 6);
  --margin-07: calc(var(--margin-01) * 7);
  --margin-08: calc(var(--margin-01) * 8);
  --margin-09: calc(var(--margin-01) * 9);
  --margin-10: calc(var(--margin-01) * 10);

  --max-width-wraper: 100em;

  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.33rem;
  --size-600: 1.77rem;
  --size-700: 2.36rem;
  --size-800: 3.15rem;
  --size-900: 4.2rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: var(--color-black);
    --bg-alt: var(--color-gray-dark);

    --fg: var(--color-white);
    --fg-alt: var(--color-gray-light);

    --hl-01: #ec008c;
    --hl-02: #fdd000;
    --border: var(--bg-alt);
  }
}
